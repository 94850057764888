import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AchievementsService } from '../../services/achievements.service';
import { LeaderboardService } from '../../services/leaderboard.service';
import { NftService } from '../../services/nft.service';
import {
  UniverseActions,
  claimBadge,
  loginAchievement,
} from '../actions/universe.actions';

@Injectable()
export class UniverseEffects {
  loadLeaderboard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UniverseActions.loadLeaderboard),
      mergeMap((action) =>
        from(
          this.leaderboardService.getLeaderboardByName(action.leaderboardName)
        ).pipe(
          map((data) => UniverseActions.loadLeaderboardSuccess({ data })),
          catchError((error) =>
            of(UniverseActions.loadLeaderboardFailure({ error }))
          )
        )
      )
    )
  );

  getUserEntry$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UniverseActions.getUserEntry),
      mergeMap((action) =>
        from(this.leaderboardService.getUserEntry(action.leaderboardId)).pipe(
          map((data) => UniverseActions.getUserEntrysSuccess({ data })),
          catchError((error) =>
            of(UniverseActions.getUserEntryFailure({ error }))
          )
        )
      )
    )
  );

  getVirtualItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UniverseActions.getVirtualItems),
      mergeMap((action) =>
        from(this.leaderboardService.getVirtualItemTags(action.tag)).pipe(
          map((data) => UniverseActions.getVirtualItemsSuccess({ data })),
          catchError((error) =>
            of(UniverseActions.getVirtualItemsFailure({ error }))
          )
        )
      )
    )
  );

  getInventory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UniverseActions.getInventory),
      mergeMap(() =>
        from(this.nftService.getInventory()).pipe(
          map((data) => UniverseActions.getInventorySuccess({ data })),
          catchError((error) =>
            of(UniverseActions.getInventoryFailure({ error }))
          )
        )
      )
    )
  );

  claimBadge$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UniverseActions.claimBadge),
      mergeMap((action) =>
        from(this.nftService.claimNft([action.virtualItemId])).pipe(
          map(() => UniverseActions.claimBadgeSuccess()),
          catchError((error) =>
            of(UniverseActions.claimBadgeFailure({ error }))
          )
        )
      )
    )
  );

  loginAchievement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UniverseActions.loginAchievement),
      mergeMap(() =>
        from(this.achievementsService.loginAchievement()).pipe(
          map(() => UniverseActions.loginAchievementSuccess()),
          catchError((error) =>
            of(UniverseActions.loginAchievementFailure({ error }))
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private achievementsService: AchievementsService,
    private leaderboardService: LeaderboardService,
    private nftService: NftService
  ) {}
}
