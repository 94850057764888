@if (loading ){

<app-preloader></app-preloader>
} @if (showCampaign) {
<div class="fade-in">
  <div>
    <router-outlet></router-outlet>
    <div class="stars-bg"></div>
  </div>
</div>
} @else {
<div
  *ngIf="showCountdown && !loading"
  class="stars-bg h-full max-h-screen flex flex-col justify-start items-center text-white"
>
  <a
    href="https://myplay.network/"
    class="logo-container pointer-events-auto"
    target="_blank"
  >
    <img
      src="assets/images/logo-white.png"
      alt="PLAY"
      class="logo h-9 p-8 md:mt-30"
    />
  </a>

  <div class="flex-1 flex flex-col justify-center items-center p-8">
    <div class="relative flex font-digital text-pink">
      <div class="relative mx-4 text-right">
        <div
          class="text-pink text-right antialiased opacity-20 text-5xl sm:text-9xl neon-text"
        >
          000
        </div>
        <div
          class="absolute text-right antialiased text-5xl sm:text-9xl neon-text top-0 left-0"
        >
          {{ countdownDays }}
        </div>
        <div class="font-bold">DAYS</div>
      </div>
      <div class="relative mx-4 text-right">
        <div
          class="text-pink opacity-20 text-right text-5xl sm:text-9xl neon-text"
        >
          00
        </div>
        <div
          class="absolute antialiased text-5xl text-right sm:text-9xl neon-text top-0 left-0"
        >
          {{ countdownHours }}
        </div>
        <div class="font-bold">HOURS</div>
      </div>
      <div class="relative mx-4 text-center">
        <div
          class="text-pink antialiased text-right opacity-20 text-5xl sm:text-9xl neon-text"
        >
          00
        </div>
        <div
          class="absolute antialiased text-right text-5xl sm:text-9xl neon-text top-0 left-0"
        >
          {{ countdownMinutes }}
        </div>
        <div class="font-bold">MINUTES</div>
      </div>
      <div class="relative mx-4 text-center">
        <div
          class="text-pink antialiased opacity-20 text-right text-5xl sm:text-9xl neon-text"
        >
          00
        </div>
        <div
          class="absolute antialiased text-5xl text-right sm:text-9xl neon-text top-0 left-0"
        >
          {{ countdownSeconds }}
        </div>
        <div class="font-bold">SECONDS</div>
      </div>
    </div>
    <p
      class="mb-4 md:mb-0 font-effraHeavy mt-20 uppercase text-6xl md:text-8xl"
    >
      Coming soon
    </p>
    <div class="text-center">
      @if (!(isLoggedIn$ | async)) {
      <p class="font-poppins text-violet mb-4">
        Get a head start, create your PLAY account now!
      </p>
      <a
        class="block w-auto mt-4 md:mt-0 action-button cursor-pointer w-20"
        (click)="createAccount()"
        >Create account</a
      >
      } @else {
      <p class="font-poppins text-xl text-violet mb-4">
        Thank you for signing in!
      </p>
      }
    </div>
  </div>
</div>

}
