import { Action, createReducer, on } from '@ngrx/store';
import { UniverseActions } from '../actions/universe.actions';
import { Inventory } from '../../interfaces/inventory.interface';
import { Leaderboard } from '../../interfaces/leaderboard.interface';
import { UserEntry } from '../../interfaces/entry.interface';

export interface UniverseState {
  leaderboardData: Leaderboard | undefined;
  userEntry: UserEntry | undefined;
  virtualItems: any[];
  inventory: Inventory | undefined;
}

export const initialState: UniverseState = {
  leaderboardData: undefined,
  userEntry: undefined,
  virtualItems: [],
  inventory: undefined,
};

const _universeReducer = createReducer(
  initialState,
  on(UniverseActions.loadLeaderboardSuccess, (state, { data }) => ({
    ...state,
    leaderboardData: data,
  })),
  on(UniverseActions.loadLeaderboardFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(UniverseActions.getUserEntrysSuccess, (state, { data }) => ({
    ...state,
    userEntry: data,
  })),
  on(UniverseActions.getUserEntryFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(UniverseActions.getVirtualItemsSuccess, (state, { data }) => ({
    ...state,
    virtualItems: data,
  })),
  on(UniverseActions.getVirtualItemsFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(UniverseActions.getInventorySuccess, (state, { data }) => ({
    ...state,
    inventory: data,
  })),
  on(UniverseActions.getInventoryFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(UniverseActions.claimBadgeSuccess, (state) => ({
    ...state,
  })),
  on(UniverseActions.claimBadgeFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(UniverseActions.loginAchievementSuccess, (state) => ({
    ...state,
  })),
  on(UniverseActions.loginAchievementFailure, (state, { error }) => ({
    ...state,
    error,
  }))
);

export function universeReducer(
  state: UniverseState = initialState,
  action: Action
): UniverseState {
  return _universeReducer(state, action);
}
