import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterOutlet, RouterLink, Router } from '@angular/router';
import { AuthService } from './services/auth.service';
import { LeaderboardService } from './services/leaderboard.service';
import { PreloaderComponent } from './components/preloader/preloader.component';
import { filter, Observable, of, switchMap, tap, timer } from 'rxjs';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, CommonModule, RouterLink, PreloaderComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  title = 'readygg-campaigns';
  targetDate: Date | undefined;
  //targetDate: Date = new Date('2025-06-06'); // TODO: Replace with actual date
  countdownDays: string = '00';
  countdownHours: string = '00';
  countdownMinutes: string = '00';
  countdownSeconds: string = '00';
  showCampaign: boolean = true;
  showCountdown: boolean = false;
  isLoggedIn$: Observable<boolean> = of(false);
  loading = true;
  isRouteLoading = true;

  constructor(
    private readonly authService: AuthService,
    private leaderboardService: LeaderboardService,
    private router: Router
  ) {}
  ngOnInit() {
    this.isLoggedIn$ = this.authService.isAuthenticated$;
    this.fetchResources();
  }

  async fetchResources() {
    await this.getEventDates();
    // this.updateCountdown();
    // setInterval(() => this.updateCountdown(), 1000);
  }

  async getEventDates() {
    return this.leaderboardService
      .getLeaderboardByName('coinlist_presale_event')
      .then((_data) => {
        this.targetDate = new Date(_data.time.start);
        this.loading = false;
      });
  }

  async updateCountdown() {
    const now = new Date();
    const timeDifference = this.targetDate!.getTime() - now.getTime();

    if (timeDifference <= 0) {
      this.showCampaign = true;
      this.countdownDays = '00';
      this.countdownHours = '00';
      this.countdownMinutes = '00';
      this.countdownSeconds = '00';
    } else {
      this.showCountdown = true;
      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor(
        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
      this.countdownDays = this.padNumber(days);
      this.countdownHours = this.padNumber(hours);
      this.countdownMinutes = this.padNumber(minutes);
      this.countdownSeconds = this.padNumber(seconds);
    }
  }

  padNumber(number: number): string {
    return number < 10 ? '0' + number : number.toString();
  }

  createAccount() {
    this.authService.runOAuth('signup');
  }
}
