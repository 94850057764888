import { ActionReducerMap } from '@ngrx/store';
import { universeReducer, UniverseState } from './reducers/universe.reducer';

export interface RootState {
  universe: UniverseState;
}

export const reducers: ActionReducerMap<RootState> = {
  universe: universeReducer,
};
