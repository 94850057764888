import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlService } from './url.service';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';
import { UserEntry } from '../interfaces/entry.interface';

@Injectable({ providedIn: 'root' })
export class LeaderboardService extends UrlService {
  private readonly entries = new BehaviorSubject<any>(null);

  constructor(private readonly _http: HttpClient) {
    super();
  }

  public async getUserEntry(leaderboardId: string): Promise<UserEntry> {
    const url = this.getUrlGen1('leaderboardV2-getUserEntry');
    const data = {
      appId: environment.projectId,
      leaderboardId,
    };
    const response = await lastValueFrom(this._http.post<UserEntry>(url, data));
    return response;
  }

  public async getLeaderboardByName(requestName: string) {
    const url = this.getUrlGen1('leaderboardV2-getByRequestName');
    const data = {
      appId: environment.projectId,
      requestName,
    };
    const response = await lastValueFrom(this._http.post<any>(url, data));
    return response;
  }

  public async getVirtualItemTags(tag: string) {
    const url = this.getUrlGen1('virtualItemsV2-getByTags');
    const data = {
      appId: environment.projectId,
      tags: [tag],
    };
    const response = await lastValueFrom(this._http.post<any>(url, data));
    return response;
  }
}
